<template>
    <v-container
        v-resize="onResize"
        fluid
        :style="`display:flex;background-color: #FAFAFA; height: ${height}px`"
    >
        <v-row
            no-gutters
            style="display:flex;justify-content:center;align-items:center"
        >
            <v-spacer />
            <v-col cols="6">
                <v-img
                    class="mb-2"
                    style="margin-left:auto; margin-right:auto"
                    contain
                    src="../assets/PROJECT_AGENDA.png"
                    max-height="320px"
                    max-width="320px"
                />
                <div style="display: flex; justify-content: center">
                    <v-progress-circular
                        dark
                        indeterminate
                        size="44"
                        class="progress_circular"
                    />
                </div>
            </v-col>
            <v-spacer />
        </v-row>
    </v-container>
</template>

<script>
import API from '../services/api'
import { mapActions } from 'vuex'

export default {
    name: 'LaunchScreen',
    props: {
        currentPath: String,
        uid: String,
    },
    data() {
        return {
            height: 0,
        }
    },
    methods: {
        ...mapActions(['actionSaveUserRef']),
        onResize() {
            this.height = window.innerHeight
        },
    },
    async mounted() {
        try {
            const {
                data: { user },
            } = await API.getMyInfo()
            this.actionSaveUserRef(user)
            localStorage.setItem('user', JSON.stringify(user))
            let path = user.permissions.includes('readTasks')
                ? 'tasks'
                : 'main-board'
            path = user.operatorRole ? 'operator' : path
            if (this.currentPath.includes(path) || this.currentPath === '/') {
                this.$router.push({ path: path }).catch(error => {
                    error && true
                })
            } else {
                this.$router.push({ path: this.currentPath }).catch(error => {
                    error && true
                })
            }
        } catch (error) {
            this.$router.push({ name: 'sign in' })
        }
    },
}
</script>

<style scoped>
.loading_data {
    color: rgb(0, 0, 0);
    text-align: center;
}
.progress_circular {
    color: #1098f3;
    margin-left: auto;
    margin-right: auto;
}
</style>
